import { render, staticRenderFns } from "./xieyis.vue?vue&type=template&id=51daae86&scoped=true"
import script from "./xieyis.vue?vue&type=script&lang=js"
export * from "./xieyis.vue?vue&type=script&lang=js"
import style0 from "./xieyis.vue?vue&type=style&index=0&id=51daae86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51daae86",
  null
  
)

export default component.exports